<template>
	<!-- 进入批改主页面 -->
	<div class="Correct container">
		<div class="Correct_con">
			<div class="Correct_con_left col-md-2">
				<div class="Correct_con_left_top">
					<el-select clearable filterable class="Correct_con_left_select" v-model="check_user_type">
						<!-- <el-option v-for="(item,index) in ['全部学生','已提交','未提交','已批改','未批改']" :key="item" :label="item" :value="item"> -->
						<el-option v-for="(item, index) in ['全部学生', '已提交', '未提交']" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</div>
				<div class="Correct_con_left_con">
					<div v-if="check_user_type == '已提交' || check_user_type == '全部学生'">
						<div class="check_user_type" v-for="item in corrected_users" :key="item.id"
							v-on:click="handleclick(item)"
							:style="{ 'color': (userId == item.id ? '#3d8cff' : item.checkStatus == 6 ? 'green' : '#000') }">
							<div style="display: flex;">
								<img src="../../assets/img/02/男生头像.png">
								<span>{{ item.name }}</span>
							</div>
							<div style="white-space: nowrap;">
								{{ $store.state.teachlanguagedata.Submitted }}
							</div>
							<div v-if="item.checkStatus == 6" class="banyuan"></div>
						</div>
					</div>
					<div v-if="check_user_type == '未提交' || check_user_type == '全部学生'">
						<div class="check_user_type" v-for="item in unjoin_users" :key="item.id"
							v-on:click="handleclick(item)"
							:style="{ 'color': (userId == item.id ? '#3d8cff' : item.checkStatus == 6 ? 'green' : '#000') }">
							<div style="display: flex;">
								<img src="../../assets/img/02/男生头像.png">
								<span>{{ item.name }}</span>
							</div>
							<div style="white-space: nowrap;">
								{{ $store.state.teachlanguagedata.NotSubmitted }}
							</div>
							<div v-if="item.checkStatus == 6" class="banyuan"></div>
						</div>
					</div>
					<!-- <div v-if="check_user_type=='已批改'">
						<div class="check_user_type" v-for="item in PlanJoinResultList" :key="item.userId"
							v-on:click="handleclick(item)" v-if="item.checkStatus==6" :style="{'color': (userId==item.userId ? '#3d8cff':'#000')}">
								<div style="display: flex;">
									<img src="../../assets/img/02/男生头像.png">
									<span>{{ item.userName? item.userName:item.userId}}</span>
								</div>
								<div style="white-space: nowrap;">
									{{item.checkStatus==6?'已批改':'未批改'}}
								</div>
						</div>
					</div>
					<div v-if="check_user_type=='未批改'">
						<div class="check_user_type" v-for="item in PlanJoinResultList" :key="item.userId"
							v-on:click="handleclick(item)" v-if="item.checkStatus!=6" :style="{'color': (userId==item.userId ? '#3d8cff':'#000')}">
								<div style="display: flex;">
									<img src="../../assets/img/02/男生头像.png">
									<span>{{ item.userName? item.userName:item.userId}}</span>
								</div>
								<div style="white-space: nowrap;">
									{{item.checkStatus==6?'已批改':'未批改'}}
								</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="Correct_con_right height85vh col-md-10">
				<div style="overflow: auto; overflow-x: auto;" class="height85vh" id="scorll_scale" @mousedown="mousedown">
					<div style="position: fixed;bottom: 40px;padding: 20px;">
						<div style="margin-top: 20px;">
							<el-button size="mini" v-if="$store.state.rollShow" @click="getRollShow()" v-preventReClick>
								滚动距离</el-button>
							<el-button size="mini" v-else @click="getRollShow()" v-preventReClick>滚动缩放</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="Correction()" v-preventReClick>{{ whetherWrong ? '结束批改' : '开始批改' }}
							</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="previousPage()" v-preventReClick>上一页</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="nextPage()" v-preventReClick>下一页</el-button>
						</div>
						<el-collapse v-model="activeNames">
							<el-collapse-item :title="$store.state.teachlanguagedata.more" name="1">
								<div style="margin-top: 20px;">
									<el-button size="mini" @click="QueryPlanUserAutoErrorMaxRecords()" v-preventReClick>
										整卷重批</el-button>
								</div>
								<div v-if="userId">
									<div style="margin-top: 20px;">
										<el-button size="mini"
											@click="deviationStutes == 'teachData' ? deviationStutes = null : deviationStutes = 'teachData'"
											v-preventReClick>{{ deviationStutes == 'teachData' ? '正在设置' : '' }}老师偏移</el-button>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini"
											@click="deviationStutes == 'studentData' ? deviationStutes = null : deviationStutes = 'studentData'"
											v-preventReClick>{{ deviationStutes == 'studentData' ? '正在设置' : '' }}学生偏移
										</el-button>
									</div>
									<div v-if="deviationStutes">
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('left')">向左偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('right')">向右偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('top')">向上偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('bottom')">向下偏移
											</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="saveDeviation()" v-preventReClick>保存偏移
											</el-button>
										</div>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini" @click="evaluate()" v-preventReClick>评价</el-button>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini" @click="deleteSymbol()" v-preventReClick>{{
											JSON.parse(this.$store.state.deleteSymbolShow) ? '点击关闭删除符号' : '点击开启删除符号'
										}}</el-button>
									</div>
									<!-- <div style="margin-top: 20px;">
										<el-button size="mini" @click="Repaint()" v-preventReClick>{{
											$store.state.teachlanguagedata.redraw }}</el-button>
									</div> -->
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
					<div style="position: fixed;right: 60px;bottom: 60px;">
						<div style="margin-top: 20px;">
							<el-button size="mini" v-if="$store.state.rollShow" @click="getRollShow()" v-preventReClick>
								滚动距离</el-button>
							<el-button size="mini" v-else @click="getRollShow()" v-preventReClick>滚动缩放</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="Correction()" v-preventReClick>{{ whetherWrong ? '结束批改' : '开始批改' }}
							</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="previousPage()" v-preventReClick>上一页</el-button>
						</div>
						<div style="margin-top: 20px;">
							<el-button size="mini" @click="nextPage()" v-preventReClick>下一页</el-button>
						</div>
						<el-collapse v-model="activeNames">
							<el-collapse-item :title="$store.state.teachlanguagedata.more" name="1">
								<div style="margin-top: 20px;">
									<el-button size="mini" @click="QueryPlanUserAutoErrorMaxRecords()" v-preventReClick>
										整卷重批</el-button>
								</div>
								<div v-if="userId">
									<div style="margin-top: 20px;">
										<el-button size="mini"
											@click="deviationStutes == 'teachData' ? deviationStutes = null : deviationStutes = 'teachData'"
											v-preventReClick>{{ deviationStutes == 'teachData' ? '正在设置' : '' }}老师偏移</el-button>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini"
											@click="deviationStutes == 'studentData' ? deviationStutes = null : deviationStutes = 'studentData'"
											v-preventReClick>{{ deviationStutes == 'studentData' ? '正在设置' : '' }}学生偏移
										</el-button>
									</div>
									<div v-if="deviationStutes">
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('left')">向左偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('right')">向右偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('top')">向上偏移</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="deviation('bottom')">向下偏移
											</el-button>
										</div>
										<div style="margin-top: 20px;">
											<el-button size="mini" @click="saveDeviation()" v-preventReClick>保存偏移
											</el-button>
										</div>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini" @click="evaluate()" v-preventReClick>评价</el-button>
									</div>
									<div style="margin-top: 20px;">
										<el-button size="mini" @click="deleteSymbol()" v-preventReClick>{{
											JSON.parse(this.$store.state.deleteSymbolShow) ? '点击关闭删除符号' : '点击开启删除符号'
										}}</el-button>
									</div>
									<!-- <div style="margin-top: 20px;">
										<el-button size="mini" @click="Repaint()" v-preventReClick>{{
											$store.state.teachlanguagedata.redraw }}</el-button>
									</div> -->
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
					<Ceshi ref="Ceshi" canvasIdName="canvasIdName"></Ceshi>
					<div style="position: absolute;left: 0px;top: 0px;width: 50px;height: 50px;"
						@click="UserQuestionFetureShow = true"></div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="UserQuestionFetureShow">
			<BaseTable :tableData="UserQuestionFeture" :tableColumn="tableColumn1"></BaseTable>
		</el-dialog>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
	showLoading,
	hideLoading
} from '../../utils/Loading'
import {
	getCorrectionFromPlan
} from '../../api/Correct.js'
import {
	submitCheckStatus,
	queryListByDictValue,
	queryPlanUserQuestionFetureStations,
	queryPlanUserAutoErrorMaxRecords,
	gotPlanJoinResultList,
	autoCorrectForDotEventsv1,
	queryPlanUserMaxRecords,
} from '../../api/base.js'
import Revision from "../../components/Revision.vue"
import Ceshi from '../../components/Ceshi/index.vue'
import BaseTable from '../../components/BasicComponents/BaseTable.vue'
export default {
	name: 'Class',

	data() {
		return {
			PlanJoinResultList: [],
			intercept: false, //是的显示一道题
			whetherWrong: false,
			activeNames: null,
			tableColumn1: [{
				prop: "value_name",
				label: "题型",
			}, {
				prop: "allCount",
				label: "题目数量",
			}, {
				prop: "passCount",
				label: "通过数量",
			}, {
				prop: "passRate",
				label: "通过率",
			}],
			UserQuestionFeture: [],
			UserQuestionFetureShow: false,
			check_user_type: '全部学生', //提交状态
			corrected_users: [], //已提交
			unjoin_users: [], //未提交
			pageId: null,
			pageIds: null, //
			cur_page: 0, //第几页
			img: null, //图片地址
			userId: null, //用户Id
			img_list: null, //
			teachId: JSON.parse(localStorage.getItem('teachlogin')).id, //老师Id
			teachLoginData: JSON.parse(localStorage.getItem('teachlogin')),
			setId: JSON.parse(localStorage.getItem("teachsetId")),
			planId: JSON.parse(localStorage.getItem("teachplan_id")),
			classId: JSON.parse(localStorage.getItem("teachclass_id")),
			canvesWight: 1200, //canvse 宽
			planData: JSON.parse(localStorage.getItem('teachQuestionSetInformation')),
			// planData: this.$route.params.data, //作业相关信息  没值啊
			deviationStutes: null, //偏移状态 老师偏移还是学生偏移
		}
	},

	components: {
		Ceshi,
		BaseTable
	},
	// created(){

	// 	this.planData  = JSON.parse(localStorage.getItem('teachQuestionSetInformation'))?JSON.parse(localStorage.getItem('teachQuestionSetInformation')):this.$route.params.data
	// console.log(this.planData);
	// },
	watch: {
		'$store.state.whetherWrong'(val) {
			if (!val) {
				this.intercept = val
				this.whetherWrong = val
				this.GotPlanJoinResultList()
				this.$refs.Ceshi.init()
			}
		},
		userId(vla, olval) {
			this.$store.commit('getwhetherWrong', false)
			this.$refs.Ceshi.init()
			this.QueryPlanUserQuestionFetureStations()
			this.QueryPlanUserMaxRecords()
		}
	},
	mounted() {

		this.get_correction_from_plan();
	},
	beforeDestroy() {
		this.$store.commit('getwhetherWrong', false)
	},
	methods: {
		//重绘
		Repaint() {
			this.$refs.Ceshi.Repaint()
		},
		//提交已批改
		SubmitCheckStatus(canshu) {
			let data = {
				planId: this.planId,
				userId: canshu,
				checkStatus: '6',
			}
			return
			submitCheckStatus(data).then(res => {
				this.$message("批改完成,已提交")
			})
		},
		// 根据班级id 作业Id查询班级下用户的批改状态
		GotPlanJoinResultList() {
			let data = {
				"planId": this.planId,
				"classId": this.classId
			}
			gotPlanJoinResultList(data).then(res => {
				this.PlanJoinResultList = res.data.result
				this.PlanJoinResultList.forEach(items => {
					this.corrected_users.forEach(item => {
						if (items.userId == item.id) {
							item.checkStatus = items.checkStatus
						}
					})
					this.unjoin_users.forEach(item => {
						if (items.userId == item.id) {
							item.checkStatus = items.checkStatus
						}
					})
				})
				this.$forceUpdate()
			})
		},
		// 错题进行一键重批
		QueryPlanUserAutoErrorMaxRecords() {
			let data = {
				classId: this.classId,
				planId: this.planId,
				setId: this.setId,
				userId: this.userId,
			}
			queryPlanUserAutoErrorMaxRecords(data).then(res => {
				if (res.data.result && res.data.result.length > 0) {
					this.$message("整卷重批完成")
					res.data.result.forEach(item => {
						autoCorrectForDotEventsv1({
							dotevent: item.params
						}).then(res => { })
					})
				}
			})
		},
		Correction() {
			this.intercept = !this.intercept
			this.whetherWrong = !this.whetherWrong
			this.$store.commit('getwhetherWrong', this.whetherWrong)
			this.$refs.Ceshi.init()
			// console.log(this.PlanUserMaxRecords,'this.PlanUserMaxRecords')
			// if (this.PlanUserMaxRecords && this.PlanUserMaxRecords.length > 0) {
			// 	this.intercept = !this.intercept
			// 	this.whetherWrong = !this.whetherWrong
			// 	this.$store.commit('getwhetherWrong', this.whetherWrong)
			// 	this.$refs.Ceshi.init()
			// } else {
			// 	this.$message("该学生暂无错题")
			// }
		},
		QueryPlanUserMaxRecords() {
			// let data = {
			// 	"planId": this.planId,
			// 	"classId": this.classId,
			// 	"userId": this.userId
			// }
			// queryPlanUserMaxRecords(data).then(res => {
			// 	if (res && res.data && res.data.result && res.data.result.length > 0) {
			// 		this.filterDuplicateRemoval(res.data.result)
			// 	} else {
			// 		this.PlanUserMaxRecords = null
			// 	}
			// })
		},
		//批改结果重新排序过滤
		filterDuplicateRemoval(data) {
			this.PlanUserMaxRecords = data.filter(function (item) {
				return (!item.manual_result && item.manual_result != 0) && (item.auto_result != 1) // 过滤出来数组中>=5的元素
			})
		},
		// 获取作业通过率
		QueryPlanUserQuestionFetureStations() {
			let data = {
				planId: this.planId,
				userId: this.userId,
				setId: this.setId,
			}
			queryPlanUserQuestionFetureStations(data).then(res => {
				// console.log(res.data.result, "ress")
				this.UserQuestionFeture = res.data.result
			})
		},
		mousedown(ev) {
			var divs = document.getElementById('scorll_scale');
			//event的兼容性
			var ev = ev || event;

			//获取鼠标按下的坐标
			var x1 = ev.clientX;
			var y1 = ev.clientY;

			//获取元素的left，top值
			var l = divs.offsetLeft;
			var t = divs.offsetTop;

			//给可视区域添加鼠标的移动事件
			document.onmousemove = function (ev) {

				//event的兼容性
				var ev = ev || event;

				//获取鼠标移动时的坐标
				var x2 = ev.clientX;
				var y2 = ev.clientY;

				//计算出鼠标的移动距离
				var x = x2 - x1;
				var y = y2 - y1;

				//移动的数值与元素的left，top相加，得出元素的移动的距离
				var ls = x + l;
				//更改元素的left，top值
				divs.scrollLeft = -ls
			}

			//清除
			document.onmouseup = function (ev) {

				document.onmousemove = null;

			}
		},
		getRollShow() {
			this.$store.commit('getRollShow', !this.$store.state.rollShow)
		},
		// 切换学生
		handleclick(message) {
			if (message != undefined) {
				this.userId = message.userId ? message.userId : message.id
			}
		},
		// 获取所有页面
		get_correction_from_plan() {
			getCorrectionFromPlan({
				"plan_id": this.planId,
				"userID": this.teachId,
				"setId": this.setId,
				"class_id": this.classId
			}).then(response => {
				this.GotPlanJoinResultList()
				this.corrected_users = response.data.corrected_users;
				this.unjoin_users = response.data.unjoin_users;
				this.pageId = response.data.pageId;
				this.pageIds = response.data.pageIds;
				this.cur_page = response.data.cur_page;
				this.img_list = response.data.img_list;
				this.img = response.data.img_src;
				if (response.data.corrected_users && response.data.corrected_users.length > 0) {
					this.handleclick(this.corrected_users[0])
				} else if (response.data.unjoin_users && response.data.unjoin_users.length > 0) {
					this.handleclick(this.unjoin_users[0])
				}
				// this.$refs.Ceshi.init()
			})
		},
		//上一页
		previousPage() {
			this.$refs.Ceshi.previousPage()
		},
		//下一页
		nextPage() {
			this.$refs.Ceshi.nextPage()
		},
		//偏移
		deviation(data) {
			this.$refs.Ceshi.deviation(data, this.deviationStutes)
		},
		// 保存偏移 studentData学生 teachData老师
		saveDeviation() {
			this.$refs.Ceshi.saveDeviation(this.deviationStutes)
		},
		// 评价
		evaluate() {
			this.$refs.Ceshi.evaluate()
		},
		// 删除符号
		deleteSymbol() {
			this.getDeleteSymbolShow(!JSON.parse(this.$store.state.deleteSymbolShow))
			location.reload();
		},
		...mapMutations(["getDeleteSymbolShow"])
	}
}
</script>

<style>
.banyuan {
	width: 10px;
	background: green;
	height: 10px;
	border-radius: 5px;
}

.Correct {
	background-color: #FFFFFF;
}


.Correct_con_left {
	text-align: left;
	border-right: 1px solid #deebff;
}

.Correct_con_left_top {
	padding: 20px 10px;
	border-bottom: 1px solid #deebff;
}

.Correct_con_left_button {
	display: inline-block;
	width: 49%;
	height: 32px;
	color: #13264d;
	font-size: 14px;
	line-height: 32px;
	border-radius: 8px;
	text-align: center;
	cursor: pointer;
	background-color: #13264d;
	color: #fff;
}

.Correct_con_left_select {
	margin-top: 20px;
}

.Correct_con_left_con {
	margin-top: 20px;
	overflow: auto;
	height: 70vh;
}

.check_user_type {
	display: flex;
	margin-top: 20px;
	padding: 0 20px;
	justify-content: space-between;
	align-items: center;
}

.Correct_con_right {
	position: relative;
}

.explain_chick {
	background-color: #f1f1f1;
}
</style>
